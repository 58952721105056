import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../layout';
import { Footer } from '../sections/footer';
import { GlobalHeader } from '../sections/global-header';
import { Blog } from '../sections/blog-section';
import { CookieBanner } from '../components/cookie-banner';

export default class Page extends React.Component {
  render = () => {
    const articles = this.props.data.dato.allArticles || this.props.data.dato.forCategoryArticles;
    const category = this.props.data.dato.componentBlogCategory || false;
    const categories = this.props.data.dato.allComponentBlogCategories;

    return (
      <Layout favicon={this.props.pageContext.favicon}>
        <GlobalHeader {...this.props.pageContext.header} white />
        <main>
          <Blog {...{articles,categories,category}} />
        </main>
        <Footer {...this.props.pageContext.footer} />
        <CookieBanner {...this.props.pageContext.cookie} />
      </Layout>
    )
  }
}

export const query = graphql`
  query BlogLandingQuery($id: DatoCMS_ItemId!, $showAll: Boolean!) {
    dato {
      allComponentBlogCategories {
        __typename
        title
        slug
      }
      allArticles: allBlogArticles @include(if: $showAll) {
        __typename
        title
        slug
        author
        date
        image {
          url(imgixParams: { w: 746, h: 380, fit: crop })
          alt
        }
        category {
          title
          slug
        }
      }
      componentBlogCategory(filter: { id: { eq: $id } }) @skip(if: $showAll) {
        __typename
        title
        slug
      }
      forCategoryArticles: allBlogArticles(filter: { category: { eq: $id } }) @skip(if: $showAll) {
        __typename
        title
        author
        date
        image {
          url(imgixParams: { w: 746, h: 380, fit: crop })
          alt
        }
        category {
          title
          slug
        }
      }
    }
  }
`;